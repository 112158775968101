@import 'styles/breakpoints.scss';

.isi {
  padding: 48px $container-padding;

  @include breakpoint(lg, min) {
    padding: 72px 0;
  }
}

.isi.alternative {
  @include breakpoint(lg, min) {
    padding: 72px $container-padding;
  }
}
