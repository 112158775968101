.accordionContent {
  width: 100%;
  overflow: hidden;
  max-height: 0;
  visibility: hidden;
  transition: max-height 1s cubic-bezier(0.1, 1, 0.1, 1), visibility 1s ease;
}

.header {
  display: block;
}

.item .accordionContent.expanded {
  max-height: 8000px;
  visibility: visible;
  transition: max-height 1s ease-in, visibility 1s ease-in;
}
