@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.header {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 300;

  @include breakpoint($custom-nav-breakpoint, min) {
    position: sticky;
    top: 0;
    z-index: 300;
  }
}

.mobileNexplanonLogo {
  display: inline-block;
  max-width: 233px;
  width: 100%;
  margin-top: 5px;
}

.mobileNavigation {
  height: 82px;
  box-shadow: 0 4px 8px $brand-shadow;
  background: $brand-white;
  @include top-navigation;
  display: flex;
  align-items: center;

  nav {
    height: 82px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    ul + button {
      margin-left: 32px;
    }
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    display: none;
  }

  img {
    margin-top: 4px;
  }
}

.buttonWrapper {
  @include resetButtonWrapper;
  width: 88px;
  height: 82px;
  position: absolute;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.menuOpened {
    background-color: $brand-primary;

    .line {
      background: $text-color-primary;
    }
    .firstLine {
      transform: translateY(10px) translateX(0) rotate(45deg);
    }

    .secondLine {
      opacity: 0;
    }

    .thirdLine {
      transform: translateY(-16px) translateX(0) rotate(-45deg);
    }
  }

  .hamburgerIcon {
    position: relative;
    width: 32px;
    height: 27px;
  }

  .line {
    display: block;
    background: $text-color-primary;
    width: 32px;
    height: 3px;
    position: absolute;
    left: 0;
    border-radius: 5px;
    transition: all 0.4s;
  }

  .firstLine {
    top: 0%;
  }

  .secondLine {
    top: 50%;
  }

  .thirdLine {
    top: 100%;
  }

  &:hover {
    background: $brand-primary;

    .line {
      background: $text-color-primary;
    }
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    display: none;
  }
}

.modalButton {
  display: none;

  @include breakpoint($custom-nav-breakpoint, min) {
    display: block;
  }
}

.desktopNavigation {
  display: none;
  @include breakpoint($custom-nav-breakpoint, min) {
    display: block;
  }
}
