@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'styles/flexbox.scss';
@import 'styles/variables.scss';

.wrapper {
  background: $brand-primary;
  padding: 48px 0px;

  .logo {
    max-width: 228px;
    height: auto;
    @include breakpoint(lg, min) {
      max-width: 203px;
    }
  }

  .split {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 48px;
    align-items: center;
  }

  .breakpointContainer {
    width: 100%;
  }

  .mobileBlock {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .desktopBlock {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 26px;

    ul {
      max-width: 220px;
      min-width: 220px;
    }

    .columnContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .listSubitems {
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 0px;
    max-width: calc(100% - 56px);

    a {
      @include footer-navigation-list;
    }
  }

  .singleLinks {
    margin-top: 16px;
    list-style-type: none;
    padding-left: 0;

    a {
      @include footer-title-list;
      text-decoration: none;
    }

    svg {
      width: 20px;
    }
  }

  .singleLink {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .noDecoration {
    a,
    button {
      text-decoration: none;
    }
  }

  @include breakpoint(lg, min) {
    padding: 64px 0px;

    .split {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  @include breakpoint(xl, min) {
    .desktopBlock {
      justify-content: flex-end;
    }
  }
}

.altSplit[class*='_split_'] {
  column-gap: 84px;
}

.altSplit .altFooterList[class*='FooterList_'] {
  width: 100%;
  max-width: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 16px;
  column-gap: 28px;

  [class*='_link_'] {
    text-decoration: none;
  }
}

.altSplit {
  .altFooterList[class*='FooterList_'],
  .quickLinks {
    [class*='_link_'] {
      width: 100%;
      justify-content: space-between;

      span {
        width: 100%;
        justify-content: space-between;
      }

      svg {
        flex: none;
      }
    }
  }

  .singleLinks {
    li + li {
      margin-top: 16px;
    }

    a[class*='_link_'] {
      width: 100%;
    }

    a[class*='_link_'] span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.wrapper.alternative {
  padding: 48px 32px;

  @include breakpoint(lg, min) {
    padding: 64px 0px;

    .split {
      align-items: center;
    }
  }
}
