@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'styles/flexbox.scss';
@import 'styles/variables.scss';

.link {
  text-decoration: underline;
  width: fit-content;
}

button.link {
  background: transparent;
  padding: 0;
  margin: 0;
  display: inline-block;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
