@import 'styles/typography';
@import 'styles/breakpoints.scss';

.richText {
  @include body;

  h1 {
    @include h1;
  }

  h2 {
    @include h2;
  }

  h3 {
    @include h3;
  }

  h4 {
    @include h4;
  }

  h5 {
    @include h5;
  }

  sup {
    font-size: 60%;
  }

  strong {
    font-weight: $font-weight-bold;
  }

  ul {
    margin: 8px 0 0;
    padding-left: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 22px;
    }

    li + li {
      margin-top: 16px;
    }

    li::before {
      content: '•';
      font-size: $font-size-lg;
      position: absolute;
      left: 7px;
      top: -2px;
    }

    & + p {
      margin-top: 16px;
    }
  }

  :global {
    .h1 {
      @include h1;
    }

    .h2 {
      @include h2;
    }

    .h3 {
      @include h3;
    }

    .h4 {
      @include h4;
    }

    .h5 {
      @include h5;
      color: $brand-primary;
    }

    .h5-mobile {
      @include h5-mobile;
      color: $brand-primary;
    }

    $mb: 8px;
    .mb {
      margin-bottom: $mb;
    }

    .mb-3 {
      margin-bottom: $mb * 3;
    }

    .intro {
      @include intro;
    }

    .intro-large {
      @include intro-large;
    }

    .social-paragraph {
      @include social-paragraph;
    }

    .bold {
      font-weight: $font-weight-bold;
    }

    .primary {
      li::before {
        content: '•';
        font-size: 28px;
        position: absolute;
        left: 4px;
        top: -10px;
        color: $brand-secondary-3;

        @include breakpoint(md, min) {
          top: -6px;
        }
      }
    }

    .black-bullets {
      li::before {
        content: '';
        font-size: $font-size-lg;
        position: absolute;
        left: 0px;
        top: 7px;
        width: 5px;
        height: 5px;
        background: currentColor;
        border-radius: 50%;

        @include breakpoint(md, min) {
          width: 6px;
          height: 6px;
          top: 6px;
        }
      }
    }

    .nowrap {
      white-space: nowrap;
    }

    .primary-list {
      li {
        padding-left: 32px;
      }

      li::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        border: 1px solid $brand-scroll-thumb;
      }

      li.graphic-blue::before {
        background-color: $brand-uterus-graphic-blue;
      }

      li.graphic-yellow::before {
        background-color: $brand-uterus-graphic-yellow;
      }

      li.graphic-red::before {
        background-color: $brand-uterus-graphic-red;
      }

      li.graphic-primary::before {
        background-color: $brand-secondary-5-tints-100;
      }
    }

    .eyebrow {
      @include primary-links;
      color: $brand-primary;
      position: relative;
      display: inline-block;
      padding-bottom: 12px;
      margin-bottom: 24px;

      &::after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        border: 2px solid $brand-tertiary;
      }
    }

    a {
      color: $text-color-link;
      text-decoration: none;

      &:hover {
        color: $text-color-link-hover;
      }
    }

    // Colors
    .primary-c {
      color: $brand-primary;
    }

    ul.linkList {
      li {
        padding-left: 0;
      }

      li:before {
        display: none;
      }

      li + li {
        margin-top: 16px;
      }

      a {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: $black-color;
          text-decoration: none;
        }
      }
    }

    .caption {
      @include caption;
    }

    .large-h2 {
      @include breakpoint(lg, min) {
        font-size: 44px;
        line-height: 1.136;
      }
    }

    .text-uppercase {
      text-transform: uppercase;
    }

    ul.tightened {
      li + li {
        margin-top: 8px;
      }
    }
  }

  p + p {
    margin-top: 8px;
  }
}
