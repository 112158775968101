@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.mobileMenu {
  position: fixed;
  left: 0;
  bottom: 82px;
  width: 100%;
  z-index: 360;
  height: calc(100vh - 82px);
  overflow-y: auto;
  font-size: $font-size-mobile-navigation;
  display: flex;
  flex-direction: column;
  background: $brand-primary;
  justify-content: flex-end;

  .mainSection {
    background: $brand-primary;
    padding: 32px;
    overflow-y: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    a,
    span,
    button.topNavigation {
      display: flex;
      justify-content: space-between;
    }

    button.topNavigation {
      @include resetButtonWrapper;
    }

    svg {
      width: 17px;
      min-width: 17px;
      transform: rotate(270deg);
    }

    .ctaBlock {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      margin: 32px 0px;
    }

    .mobileItem {
      &:hover {
        color: $brand-white;
      }
    }
  }

  .subMenu {
    background: $brand-primary-tints-100;
    height: 100%;

    .backButton {
      @include resetButtonWrapper;
      background: $brand-primary;
      position: fixed;
      width: 100%;
      padding: 24px 32px;
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;
      @include mobile-navigation;

      &:hover {
        color: $brand-white;
      }

      svg {
        width: 17px;
        transform: rotate(90deg);
      }
    }

    .childItems {
      margin-top: 75px;
      padding: 39px 32px 32px;
      @include mobile-navigation;
      background: $brand-primary-tints-100;

      ul {
        padding-inline-start: 0px;
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 24px;

        li {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  button.mobileItem {
    @include resetButtonWrapper;
  }

  .mobileItem {
    @include mobile-navigation;
    cursor: pointer;
  }

  .mobileList {
    display: flex;
    flex-direction: column;
    padding: 0px;
    list-style: none;
    gap: 32px;
    margin: 0px;

    li {
      width: 100%;
    }

    span,
    button.mobileItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    > li.active {
      > a {
        border-left: 3px solid white;
        padding-left: 24px;
      }
    }

    [class*='_accordion_'] {
      z-index: 100;
    }

    .showOptions {
      padding: 0px;
    }

    :last-child {
      border-bottom: none;
    }
  }

  @include breakpoint(lg, min) {
    display: none;
  }
}

.subitemList {
  display: flex;
  flex-direction: column;
  gap: 24px;

  a,
  span,
  button.topNavigation {
    display: flex;
    align-items: center;
    @include button;
    color: $text-color-primary;
    text-decoration: none;

    &:hover {
      color: $brand-white;
    }
  }

  button.topNavigation {
    @include resetButtonWrapper;
  }

  span {
    cursor: pointer;
  }

  svg {
    margin-left: 4px;
    transform: scale(0.8);
  }
}

.modalButton {
  margin: 16px 50px;
  background: $brand-white;

  &:hover {
    background: $brand-white;
  }
}

.activeSubItem {
  text-decoration: underline;
}

.active {
  .mobileItem {
    color: $brand-white;
    border-left: 3px solid $brand-white;
    padding-left: 24px;
  }
}

.subNavWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.subNavigation {
  padding: 16px 32px;
  background: $brand-primary;
}

.ctaBlockSub {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: 8px 0px 24px 0px;
}
