@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'styles/variables.scss';
@import 'Nori/Button/mixin.scss';

.wrapper {
  padding: 20px 0;

  &.secondary2Light {
    background: $brand-secondary-2-tints-100;
  }

  &.secondary4Light {
    background: $brand-secondary-4-tints-100;
  }

  .containerWrap {
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 14px;
  }

  .copy {
    max-width: 834px;

    p {
      font-size: $font-size-md;
    }

    .body {
      line-height: 1.5;
    }

    p + p {
      margin-top: 0;
    }
  }

  @include breakpoint(lg, min) {
    padding: 14px 0px;
    height: 71px;

    .mainContainer {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      gap: 24px;
    }
  }
}
