@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

div.isiModal {
  height: 100%;

  [class*='_modalContainer_'] {
    height: 100%;
  }

  [class*='_modalContent_'] {
    padding: 0px;
  }

  .modalHeader {
    background: $brand-primary-tints-200;
    border-radius: 8px;
    padding: 16px 32px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    h3 {
      @include h3-modal;
    }

    .closeIcon {
      position: absolute;
      right: 8px;
      top: 8px;
      height: 39px;
      width: 39px;
      cursor: pointer;
    }

    button.closeIcon {
      margin: 0;
      padding: 0;
      border: 0;
      background-color: transparent;
      -webkit-appearance: none;
      appearance: none;
      svg {
        height: 39px;
        width: 39px;
      }
    }
  }

  .modalBody {
    background: $brand-white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 32px 0;

    @include breakpoint(lg, min) {
      padding: 24px 32px;
    }
  }

  .isiWrapper {
    padding: 0px 24px 0px;

    @include breakpoint(lg, min) {
      padding: 0px;
      max-height: 100%;
    }
  }
}

button.closeIcon {
  @include resetButtonWrapper;
}

.closeIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 29px;
  height: 29px;
  color: $brand-slate-dark;
  cursor: pointer;
}

.buttonBlock {
  margin-top: 24px;
  display: flex;
  gap: 20px;
}

.topBlock {
  margin-bottom: 24px;
}

.indicationBlock {
  margin-bottom: 32px;
}

.indicationCopy {
  padding: 24px 32px;
  @include body;
  display: none;

  @include breakpoint(lg, min) {
    display: block;
  }
}

.secondaryTitle {
  background: $brand-primary-tints-200;
  padding: 16px 32px;
  display: none;

  h3 {
    @include h3-modal;
  }

  @include breakpoint(lg, min) {
    display: block;
  }
}

.indicationTitle {
  display: none;
  @include breakpoint(lg, min) {
    display: block;
  }
}

.isiTitle {
  display: show;
  @include breakpoint(lg, min) {
    display: none;
  }
}
