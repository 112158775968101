@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'styles/flexbox.scss';
@import 'styles/variables.scss';

.list {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    @include footer-title-list;
  }

  a,
  span {
    @include footer-navigation-list;
    display: flex;
    align-items: center;
  }

  button[class*='_link_'] {
    display: flex;
    align-items: center;
    @include footer-navigation-list;
  }

  span {
    text-decoration: none;
    cursor: pointer;
  }

  svg {
    width: 16px;
  }

  @include breakpoint(lg, min) {
    gap: 8px;
  }
}
