@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';

.stickyNavigation {
  position: fixed;
  bottom: 82px;
  left: 0;
  width: 100%;
  z-index: 120;
  display: flex;

  @include breakpoint($custom-nav-breakpoint, min) {
    display: none;
  }
}

.stickyRow {
  background: $brand-primary-tints-100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  @include breakpoint($custom-nav-breakpoint, min) {
    display: none;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    @include stick-navigation;
    width: 100%;
    height: 44px;
    padding: 4px 16px;

    &:hover {
      color: $text-color-utulity-hover;
      background: $brand-primary-tints-200;
    }
  }

  a.mainCta {
    background: $brand-secondary-2;
    font-weight: $font-weight-bold;
    color: $white-color;

    &:hover {
      background: $brand-secondary;
    }
  }

  a + a {
    border-left: 1px solid $brand-primary-tints-200;
  }
}

// Alternative variant
.stickyNavigation.alternative {
  .stickyRow {
    a {
      font-size: $font-size-xs;
      color: $text-color-primary;
      font-weight: $font-weight-normal;
      padding: 0 8px;
      line-height: 1.28;
    }

    a.mainCta {
      background: $brand-primary;
      font-weight: $font-weight-bold;
      color: $text-color-primary;
    }

    a + a {
      border-left: 1px solid $brand-white;
    }
  }
}

// Orphan page / Social / Linktree navigation styles
.orphanStickyNavigation {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 120;
  display: flex;
}

.orphanStickyRow {
  background: $brand-primary-tints-100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: $brand-primary;

  a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include stick-navigation;
    height: 51px;
    padding: 0px 16px;
    background: $brand-primary;
    font-weight: $font-weight-normal;

    @include breakpoint(md, min) {
      padding: 0 30px;
    }

    &:nth-child(1) {
      border-right: 1px solid $brand-primary-tints-400;
    }

    &:nth-child(2) {
      justify-content: flex-start;
      border-left: 1px solid $brand-primary-tints-400;
    }
  }
}
