@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.header {
  display: block;
}

.faqItemTitle {
  display: block;
  flex: 1;
  @include footer-title-list;
}

.accordionButton {
  @include resetButtonStyles;
  padding: 0px 0px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background: $transparent;
  width: 100%;

  svg {
    color: $text-color-primary;
    transition: transform $timing-elastic;
    height: 16px;
  }
}
