@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'styles/containers.scss';

.container {
  @include container;

  &.medium {
    @include breakpoint(xl, min) {
      max-width: 864px + ($container-padding * 2);
    }
  }
}
