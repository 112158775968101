@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.isiFiller {
  height: 141px;

  @include breakpoint(400px, min) {
    height: 141px;
  }

  @include breakpoint(576px, min) {
    height: 124px;
  }

  @include breakpoint(706px, min) {
    height: 103px;
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    height: 0px;
  }
}

@mixin stickyBar {
  width: 100%;
  border-radius: 0;
  overflow: hidden;
  position: fixed;
  z-index: 150;
  bottom: 126px;
  padding-bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: $brand-white;
  box-shadow: $isi-bar-shadow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-timing-function: ease-in-out;
  transition: height 0.7s, visibility 0s;
  visibility: visible;
  display: none;
  width: calc(100% - 48px);
  max-width: 1282px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  @include breakpoint($custom-nav-breakpoint, min) {
    padding-bottom: 0;
    bottom: 0;
    display: block;
  }
}

.stickyBar {
  @include stickyBar;
}

.stickyOrphanBar {
  @include stickyBar;

  @include breakpoint(lg, min) {
    margin-bottom: 51px;
  }
}

.mobileISI {
  background: $brand-white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  display: flex;
  flex-direction: column;

  &.transparent {
    opacity: 0.4;
    pointer-events: none;
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    display: none;
  }

  [class*='RichText_richText__'] {
    font-size: $font-size-sm;
  }

  .highlight {
    background: $brand-primary-tints-200;
    font-weight: $font-weight-bold;
  }

  .mobileIndication {
    position: relative;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    border-bottom: 1px solid $brand-primary-tints-300;
    transition: display 0.7s;

    &.hide {
      display: none;
    }

    .indicationEllipsis {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;

      &.hide {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }

    .mobileIndicationButton {
      @include resetButtonWrapper;
      min-width: 18px;
      width: 18px;
      height: 18px;
      cursor: pointer;
      display: none;
      line-height: 0;

      &.show {
        min-width: 18px;
        width: 18px;
        height: 18px;
        cursor: pointer;
        display: block;

        @include breakpoint(728px, min) {
          display: none;
        }
      }
    }
  }

  .mobileSafety {
    position: relative;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;

    .mobileSafetyText {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;

      overflow: hidden;

      @include breakpoint(sm, min) {
        -webkit-line-clamp: 3;
      }
    }
  }

  .mobileIsiButton {
    min-width: 18px;
    width: 18px;
    cursor: pointer;
  }

  button.mobileIsiButton {
    @include resetButtonWrapper;
    line-height: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.expanded {
  height: calc(100vh - 89px);

  .copyWrapper {
    height: calc(100vh - 121px);

    &::-webkit-scrollbar-track {
      margin-bottom: 0px;
    }
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    height: calc(100vh - 110px);

    .copyWrapper {
      height: calc(100vh - 142px);
    }
  }
}

.wrapper {
  width: 100%;
  max-width: 1920px;
  padding: 0px 24px;
}

.isiBarContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 1px 0 0 0;

  @include breakpoint(md, min) {
    flex-direction: row;
    padding: 2px 0px 8px 0px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 28px;
    background: $brand-primary-tints-100;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .isiBarTitle {
    display: block;
    margin-bottom: 8px;
    @include isi-desktop;

    @include breakpoint(md, max) {
      margin-bottom: 4px;
      @include intro;
    }
  }

  .desktopTitle {
    display: none;

    @include breakpoint(md, min) {
      display: block;
    }
  }

  .mobileTitle {
    display: block;

    @include breakpoint(md, min) {
      display: none;
    }
  }

  .isiBarCol {
    position: relative;

    .isiBlock {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    [class*='RichText_richText__'] {
      @include breakpoint(md, max) {
        span {
          font-size: $font-size-sm;
        }
      }
    }

    @include breakpoint(md, max) {
      padding: 8px 16px;

      .isiBarTitle {
        font-size: $font-size-sm;
      }
    }
  }

  .isiBarCol:first-child {
    .isiBarTitle {
      @include breakpoint(md, max) {
        margin-bottom: 12px;
      }
    }

    @include breakpoint(md, min) {
      width: 388px;
      padding-right: 12px;
    }
  }

  .isiBarCol:last-child {
    @include breakpoint(md, min) {
      flex: 1;
      padding-left: 12px;
    }

    @include breakpoint(md, max) {
      padding: 0;

      .isiBarTitle {
        background: $brand-primary-tints-100;
        height: 34px;
        line-height: 34px;
        padding: 0 $container-padding;
      }

      [class*='_richText_'] {
        padding: 0 $container-padding 6px;
      }
    }

    :global {
      span:not(.firstPart) {
        @include breakpoint(lg, max) {
          display: none;
        }
      }
    }
  }
}

.iconContainer {
  cursor: pointer;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 9px;
  top: 4px;
  z-index: 120;
}

button.iconContainer {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
}

.hideBar {
  visibility: hidden;
}
