@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.healthcareModal {
  text-align: left;

  h4 {
    @include h4;
    color: $black-color;
    font-variant: normal;
    margin-top: 18px;
    font-size: $font-size-xl;

    @include breakpoint(md, min) {
      font-variant: normal;
    }
  }

  p {
    margin-top: 16px;
    @include body;
    color: $black-color;
    font-size: $font-size-md;
  }

  a,
  span:not(.linkCaption),
  button {
    color: $text-color-link;
    text-decoration: none;
    font-family: $font-family-default;
    font-weight: $font-weight-semibold;
    &:hover {
      text-decoration: underline;
    }
  }

  span:not(.linkCaption),
  button {
    text-decoration: none;
    cursor: pointer;
  }

  [class*='_buttonLink_'] {
    margin-top: 40px;

    svg {
      margin-left: 12px;
    }
  }

  .linkCaption {
    display: block;
    @include caption-desktop;
    margin-top: 6px;
  }
}

.closeIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  height: 39px;
  width: 39px;
  color: $brand-slate-dark;
  cursor: pointer;
}

button.closeIcon {
  @include resetButtonWrapper;
}

.noButton {
  @include resetButtonWrapper;
  text-align: left;
}
