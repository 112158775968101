@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'styles/flexbox.scss';
@import 'styles/variables.scss';

$spacer: 29px;

.secondSection {
  padding: 24px 0px 150px;
  background-color: $brand-footer;

  @include breakpoint($custom-nav-breakpoint, min) {
    padding: 48px 0px;
  }
}

.sectionWraper {
  width: 100%;

  display: flex;
  flex-direction: column;

  .brandLogoContainer {
    display: flex;
    justify-content: center;
  }

  .brandLogo {
    width: 215px;
    height: 32px;
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .brandLogoContainer {
      justify-content: flex-start;
    }

    .brandLogo {
      width: 215px;
      height: 32px;
    }
  }
}

.copySection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;

  .copyright {
    width: 100%;
    text-align: center;
    @include footer;

    :global(.job-code) {
      white-space: nowrap;
    }
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    flex-direction: row;
    justify-content: space-between;

    .copyright {
      width: 100%;
      max-width: 836px;
      text-align: left;
    }
  }
}

.logoSection {
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  margin-left: 0px;
  margin-top: 16px;

  .sealIcon {
    width: 121px;
    height: 40px;
  }

  .allyIcon {
    width: 102px;
    height: 40px;
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    justify-content: flex-end;
    margin-top: 0px;
    margin-left: 38px;
    max-width: 316px;

    .sealIcon {
      width: 143px;
      height: 47px;
    }

    .allyIcon {
      width: 119px;
      height: 47px;
    }

    a:first-child {
      margin-right: 16px;
    }
  }
}

.navList {
  @include footer-navigation-list;
  text-align: center;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  justify-content: center;

  li {
    list-style: none;
    margin-right: 0px;
    margin-top: 16px;
  }

  span {
    cursor: pointer;
    text-decoration: underline;
    color: $brand-footer-links;
  }

  :last-child {
    margin-right: 0px;
  }

  a {
    color: $brand-footer-links;
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    text-align: left;
    flex-direction: row;
    justify-content: flex-start;

    li {
      margin-right: 16px;
      margin-top: 0px;
    }
  }
}
