@import 'styles/breakpoints';

.itemBreakpoint {
  $breakpoints: xs, sm, md, lg, xl;

  @each $breakpoint-item in $breakpoints {
    &.#{$breakpoint-item}Breakpoint {
      .largerItem {
        display: none;

        @include breakpoint($breakpoint-item, min) {
          display: block;
        }
      }

      .smallerItem {
        display: block;

        @include breakpoint($breakpoint-item, min) {
          display: none;
        }
      }
    }
  }
}
