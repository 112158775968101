@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.mobileMenu {
  position: fixed;
  left: 0;
  bottom: 82px;
  width: 100%;
  z-index: 360;
  height: calc(100vh - 82px);
  overflow-y: auto;
  font-size: $font-size-mobile-navigation;
  display: flex;
  flex-direction: column;
  background: $brand-white;
  justify-content: flex-end;

  .mainSection {
    background: $brand-white;
    padding-top: 32px;
    overflow-y: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    a,
    span,
    button.topNavigation {
      display: flex;
      justify-content: space-between;
    }

    button.topNavigation {
      @include resetButtonWrapper;
    }

    svg {
      width: 17px;
      min-width: 17px;
      transform: rotate(270deg);
    }

    .ctaBlock {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      padding: 16px 24px 30px 24px;
    }

    .mobileItem {
      &:hover {
        background: $brand-primary-tints-100;
      }
    }
  }

  .subMenu {
    background: $brand-primary-tints-100;
    height: 100%;

    .backButton {
      @include resetButtonWrapper;
      background: $brand-white;
      position: fixed;
      width: 100%;
      padding: 20px 24px;
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;
      @include mobile-navigation;
      border-bottom: 1px solid $brand-primary;

      &:hover {
        background: $brand-primary-tints-100;
      }

      svg {
        width: 17px;
        transform: rotate(90deg);
      }
    }

    .childItems {
      margin-top: 68px;
      padding: 20px 0 0;
      @include mobile-navigation;
      background: $brand-white;
      height: 100%;

      ul {
        padding-inline-start: 0px;
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        display: flex;
        flex-direction: column;

        li {
          padding: 10px 24px;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }

          &:hover {
            background: $brand-primary-tints-100;
          }
        }
      }
    }
  }

  button.mobileItem {
    @include resetButtonWrapper;
  }

  .mobileItem {
    @include mobile-navigation;
    cursor: pointer;
  }

  .mobileList {
    display: flex;
    flex-direction: column;
    padding: 0px;
    list-style: none;
    margin: 0px;
    padding: 0;

    li {
      width: 100%;
      padding: 10px 24px;

      &:hover {
        background: $brand-primary-tints-100;
      }
    }

    span,
    button.mobileItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    [class*='_accordion_'] {
      z-index: 100;
    }

    .showOptions {
      padding: 0px;
    }

    :last-child {
      border-bottom: none;
    }
  }

  @include breakpoint($custom-nav-breakpoint, min) {
    display: none;
  }
}

.subitemList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px 24px;
  background: $brand-primary-tints-100;

  a,
  span,
  button.topNavigation {
    display: flex;
    align-items: center;
    @include button;

    color: $brand-secondary-3;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    line-height: $default-line-height;

    text-decoration: none;

    &:hover {
      color: $text-color-utulity-hover;
    }
  }

  button.topNavigation {
    @include resetButtonWrapper;
  }

  span {
    cursor: pointer;
  }

  svg {
    margin-left: 4px;
    transform: scale(0.8);
  }
}

.modalButton {
  margin: 16px 50px;
  background: $brand-white;

  &:hover {
    background: $brand-white;
  }
}

.activeSubItem {
  background: $brand-primary-tints-100;
}

.active {
  background: $brand-primary-tints-100;
}

.subNavWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $brand-white;
}

.subNavigation {
  background: $brand-primary-tints-100;
}

.ctaBlockSub {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 16px 24px 26px;
  background: $brand-white;
}
