@import 'styles/typography.scss';
@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'mixin.scss';

.buttonCaption {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  column-gap: 8px;
  row-gap: 8px;
  flex-direction: row;

  &.breakOnMobile {
    @include breakpoint(xs, max) {
      flex-direction: column;
      align-items: flex-start;

      .captionItem {
        padding-left: 24px;
      }
    }
  }
}

.buttonItem {
  flex: none;
}

.captionItem {
  @include caption-desktop;
  flex: 1;
}

.buttonItem[class*='_secondary_'] ~ .captionItem {
  color: $brand-secondary;
}
