@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'styles/containers.scss';

.containerXL {
  @include containerXL;
  width: 100%;
  max-width: 1440px;

  @include breakpoint(md, min) {
    padding-left: 50px;
    padding-right: 50px;
  }
}
