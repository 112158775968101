@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.modalOverlay {
  overflow: hidden;
}

.modal,
.modalContainer {
  width: 100vw;
  height: 100vh;
  z-index: 9000;
  position: fixed;
  top: 0;
  left: 0;
}

.modalBackdrop {
  width: 100%;
  height: 100%;
  background-color: transparent;
  animation: 200ms backdropFadeIn forwards;
  position: fixed;
  left: 0;
  top: 0;
  backdrop-filter: blur(5px);
}

.modalContainer {
  z-index: 10;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 27px;

  @include breakpoint(md, min) {
    padding: 32px;
  }
}

.modalContent {
  position: relative;
  background-color: $white-color;
  padding: 32px 32px;
  width: 100%;
  margin: auto;
  max-width: 1088px;
  animation: 120ms modalFadeIn forwards 100ms;
  box-shadow: $deep-drop-shadow;
  opacity: 0;
  border-radius: 8px;
}

.modal {
  &.closing {
    pointer-events: none;

    .modalBackdrop {
      animation: 200ms backdropFadeOut forwards;
    }

    .modalContent {
      animation: 120ms modalFadeOut forwards;
    }
  }
}

.modalClose {
  position: absolute;
  right: 4px;
  top: 4px;
  border: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
}

@keyframes backdropFadeIn {
  from {
    background-color: transparent;
  }
  to {
    background-color: $brand-modal-overlay;
  }
}

@keyframes backdropFadeOut {
  from {
    background-color: $brand-modal-overlay;
  }
  to {
    background-color: transparent;
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.6);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
