@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.external {
  text-align: left;

  h4 {
    @include h4;
    color: $text-color-primary;
    margin-top: 18px;
    font-variant: none;

    @include breakpoint(md, min) {
      font-variant: none;
    }
  }

  p {
    margin-top: 16px;
    @include body;
    color: $black-color;
    font-size: $font-size-md;
  }

  [class*='_buttonLink_'] {
    background: $brand-white;
    color: $brand-primary;

    &:hover {
      background: $brand-primary;
      color: $brand-white;
    }
  }
}

button.closeIcon {
  @include resetButtonWrapper;
}

.closeIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 29px;
  height: 29px;
  color: $brand-slate-dark;
  cursor: pointer;
}

.buttonBlock {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  gap: 20px;

  [class*='_buttonCaption_'] {
    flex-direction: column;
  }

  [class*='_captionItem_'] {
    color: $brand-secondary;
  }
}
