@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';

$dropdown-width: max-content;
$navigation-height: 71px;
$alt-navigation-height: 61px;

.dropdownButton {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.mainList {
  display: none;
  flex-grow: 1;
  list-style: none;
  justify-content: flex-end;
  margin: 0px;
  height: 100%;
  @include main-navigation;
  gap: 20px;
  padding-inline-start: 0px;
  .carot {
    width: 16px;
    min-width: 16px;
    margin-left: 4px;
    color: $brand-secondary-3;
  }

  li.disabled {
    button,
    a span {
      color: $text-color-disabled;
    }

    button svg {
      color: $text-color-disabled;
    }
  }

  li:first-child {
    margin-left: 0px;
  }

  > li {
    height: 100%;
    padding-top: 6px;
    max-height: $navigation-height;
    border-bottom: 3px solid $brand-white;
    position: relative;

    @include breakpoint(xl, min) {
      max-height: $navigation-height;
    }

    a,
    .dropdownButton,
    span {
      display: flex;
      align-items: center;
      height: 100%;
      text-decoration: none;
      color: $text-color-primary;
      min-width: max-content;
    }

    @include breakpoint($custom-nav-items-breakpoint, max) {
      > a,
      > .dropdownButton,
      > span {
        min-width: min-content;
        max-width: 100px;
        text-align: center;
      }
    }

    &:nth-child(1) {
      // min-width: $dropdown-width;
      text-align: center;

      > .dropdownButton,
      > span {
        min-width: $dropdown-width;
        justify-content: center;

        @include breakpoint($custom-nav-items-breakpoint, max) {
          min-width: min-content;
          max-width: 90px;
          text-align: center;
        }
      }
    }
  }

  > li:hover,
  > li.opened {
    border-bottom: 3px solid $brand-white;
    border-bottom: none;

    .dropdown {
      display: block;
    }

    .carot {
      width: 16px;
      min-width: 16px;
      transform: rotate(180deg);
      color: $brand-secondary-3;
    }
    .dropdownButton,
    span {
      border-bottom: 3px solid $brand-secondary-2;
    }

    .dropdown {
      .dropdownButton,
      span {
        border-bottom: none;
      }
    }
  }

  > li.listSubmenu:hover {
    border-bottom: 3px solid $brand-white;
    border-bottom: none;
    > .dropdownButton,
    > span {
      border-bottom: 3px solid $brand-secondary-2;
    }
  }

  > li.active {
    border-bottom: 3px solid $brand-white;
    border-bottom: none;
    .dropdownButton,
    span {
      text-decoration: none;
      border-bottom: 3px solid $brand-secondary-2;
    }
    .dropdown {
      .dropdownButton,
      span {
        border-bottom: 3px solid $brand-white;
      }
    }
  }

  > li.active.listSubmenu {
    border-bottom: 3px solid $brand-white;
    border-bottom: none;

    .dropdownButton,
    span {
      text-decoration: none;
      border-bottom: 3px solid $brand-secondary-2;
    }

    .dropdown {
      .dropdownButton,
      span {
        border-bottom: none;
      }
    }
  }

  @include breakpoint(xl, min) {
    > li {
      margin-left: 0px;
    }

    .carot {
      width: 16px;
      min-width: 16px;
      margin-left: 4px;
      color: $brand-secondary-3;
    }
  }

  .list {
    max-height: $navigation-height;
    height: $navigation-height;
    display: flex;
    align-items: center;
    .dropdownButton,
    span {
      max-height: $navigation-height;
      border-bottom: 3px solid $brand-white;
      border-bottom: none;

      @include breakpoint(xl, min) {
        max-height: $navigation-height;
      }
    }
  }

  .listSubmenu {
    max-height: $navigation-height;
    height: $navigation-height;
    border-bottom: none;
    display: flex;
    align-items: center;

    .dropdownButton,
    span {
      max-height: $navigation-height;
      border-bottom: 3px solid $brand-white;
      @include breakpoint(xl, min) {
        max-height: $navigation-height;
      }
    }
  }
}

.dropdown {
  display: none;
  width: 100%;
  min-width: 270px;
  position: absolute;
  background: $brand-white;
  list-style: none;
  text-align: center;
  margin-top: 3px;
  top: 68px;
  left: 0%;
  padding: 0px 1rem 8px;
  box-shadow: $navigation-drop-shadow;
  padding: 14px 0;

  li {
    height: 100%;
    width: max-content;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    max-height: $navigation-height;
    width: 100%;

    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      text-decoration: none;
      text-align: center;
    }

    .dropdownButton,
    span {
      text-align: center;
      justify-content: flex-start;
      width: 100%;
      min-width: initial;
      max-width: 238px;
      text-align: left;
      border-bottom: none;
    }

    &.active .dropdownButton,
    &.active span {
      text-decoration: underline;
    }

    &:hover {
      border-bottom: 3px solid $brand-secondary-2;
      background: $brand-primary-tints-100;
      border-bottom: none;

      svg {
        color: $brand-secondary-3;
      }
    }
  }

  > li.active {
    background: $brand-primary-tints-100;

    .dropdownButton,
    span {
      text-decoration: none;
      border-bottom: none;
    }
  }
}

@include breakpoint(lg, min) {
  .mainList {
    display: flex;
  }
}

@include breakpoint(xl, min) {
  .mainList {
    .dropdown {
      font-size: $font-size-md;

      .dropdownButton,
      span {
        max-width: 238px;
      }
    }
  }

  .mainList {
    gap: 20px;
  }
}

// Alternative Styles
.mainList.altList {
  gap: 16px;

  @include breakpoint(xl, min) {
    gap: 16px;
  }

  > li {
    @include breakpoint(xl, min) {
      max-height: $alt-navigation-height;
    }
  }

  .list {
    .dropdownButton,
    span {
      @include breakpoint(xl, min) {
        max-height: $alt-navigation-height;
      }
    }
  }

  .listSubmenu {
    .dropdownButton,
    span {
      @include breakpoint(xl, min) {
        max-height: $alt-navigation-height;
      }
    }
  }
}
