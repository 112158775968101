@import 'styles/breakpoints.scss';
@import 'styles/containers.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.wrapper {
  padding: 0px 0;

  @include breakpoint(lg, min) {
    padding: 0px;
  }

  > h2 {
    @include h2;
    margin: 0 0 8px;
  }

  > h3 {
    @include h3;
    margin-top: 0px;
    margin-bottom: 13px;
  }

  .ssi {
    @include h3;
    margin-top: 13px;
    margin-bottom: 15px;
  }

  h4 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .contraindications {
    margin-top: 0px;
  }

  p {
    @include body;
  }

  ul {
    padding-left: 16px;

    li + li {
      margin-top: 12px;
    }
  }

  li {
    @include body;
  }

  .body {
    @include body;
    font-style: normal;
  }

  [class*='_richText_'] {
    .bold {
      font-weight: $font-weight-bold;
    }

    .plainHeading {
      font: inherit;
      font-weight: $font-weight-bold;
    }

    p + .plainHeading {
      margin-top: 8px;
    }
  }

  [class*='_richText_'] a,
  [class*='_poplink_'],
  a {
    color: $text-color-link;
    text-decoration: none;

    &:hover {
      color: $text-color-link-hover;
      text-decoration: underline;
    }
  }

  [class*='_richText_'] {
    .phoneLink {
      color: $text-color-primary;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        color: $text-color-primary;
        text-decoration: none;
      }
    }

    strong {
      font-weight: $font-weight-bold;
    }
  }
}

.poplink {
  text-decoration: underline;
  cursor: pointer;
}

button.poplink {
  @include resetButtonWrapper;
}

.reportFda {
  margin-top: 8px;
}
